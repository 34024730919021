import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { get as getCookie } from "es-cookie";
import { UAParser } from "ua-parser-js";

const user = new UAParser(window.navigator.userAgent);

Vue.config.productionTip = false;
Vue.use(Vuex);

declare global {
  interface Window {
    chattingStart: Function;
    chattingExit: Function;
    player: Function;
    isMobile: Function;
  }
}
export enum LiveState {
  BEFORE = "before",
  ONAIR = "onair",
  END = "end"
}

const urlParams = new URLSearchParams(window.location.search);
const startParam = Date.parse(
  urlParams.get("st") || "01 Jan 1970 00:00:00 GMT"
);
const endParam = Date.parse(urlParams.get("et") || "01 Jan 1970 00:00:00 GMT");
console.log(
  "time param: start => ",
  new Date(startParam).toLocaleString(),
  "/ end => ",
  new Date(endParam).toLocaleString()
);

const startTime = new Date(
  startParam > 0 ? startParam : Date.UTC(2020, 9, 31, 14, 0, 0)
);
const endTime = new Date(
  endParam > 0 ? endParam : Date.UTC(2020, 9, 31, 16, 0, 0)
);
const registTime = new Date(
  startParam > 0 ? startParam : Date.UTC(2020, 9, 31, 13, 0, 0)
);
const redeemActivateTime = new Date(
  startParam > 0 ? startParam : Date.UTC(2020, 9, 31, 15, 0, 0)
);

const store = new Vuex.Store({
  state: {
    registStatus: false,
    liveStatus: LiveState.BEFORE,
    redeemStatus: false,
    savedCoupon: (getCookie("coupon") || "").length === 12,
    user: user.getResult()
  },
  mutations: {
    setLiveStatus(state, lState: LiveState): void {
      state.liveStatus = lState;
    },
    setSavedCoupon(state, saved: boolean): void {
      state.savedCoupon = saved;
    },
    checkLiveStatus(state): void {
      const nowTime = new Date(Date.now());

      // console.log("checkStatus: now time => ", nowTime.toLocaleString(), "/ target time => ", startTime.toLocaleString());

      if (!state.registStatus) {
        state.registStatus = registTime < nowTime;
      }

      if (!state.redeemStatus) {
        state.redeemStatus = redeemActivateTime < nowTime;
      }

      if (startTime > nowTime) {
        state.liveStatus = LiveState.BEFORE;
      } else if (nowTime > endTime) {
        state.liveStatus = LiveState.END;
      } else {
        state.liveStatus = LiveState.ONAIR;
      }

      if (nowTime < endTime) {
        setTimeout(store.commit, 1000, "checkLiveStatus");
      }
    }
  }
});

setTimeout(store.commit, 1000, "checkLiveStatus");

new Vue({
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
