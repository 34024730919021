































import Vue from "vue";

export default Vue.extend({
  name: "LivePlayer",
  props: {
    liveStatus: String
  },
  methods: {
    setPlayer() {
      console.log("player call!!");
      window.player();
    }
  }
});
