import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.red.accent4, // #D50000
        secondary: colors.grey.darken1, // #757575
        accent: colors.indigo.base // #3F51B5
      }
    }
  }
});
