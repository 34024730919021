




















































import Vue from "vue";

export default Vue.extend({
  name: "LiveChat",
  props: {
    liveStatus: String
  },
  data: () => ({
    isOn: false,
    chatLoaded: false,
    nickname: ""
  }),
  methods: {
    enter() {
      if (this.nickname.trim().length == 0) {
        alert("Enter your Nickname");
        const elNick = document.getElementById("ChatNick");
        if (elNick != null) {
          elNick.focus();
        }
        return false;
      }
      this.isOn = true;
    },
    quit() {
      this.isOn = false;
      window.chattingExit();
    }
  },
  updated: function() {
    this.$nextTick(function() {
      if (this.isOn) {
        this.chatLoaded = true;
        window.chattingStart(this.nickname);
      } else {
        this.chatLoaded = false;
      }
    });
  }
});
