





















































import Vue from "vue";
import { set as setCookie, get as getCookie } from "es-cookie";
import wretch from "wretch";

export default Vue.extend({
  name: "RedeemCode",
  data: () => ({
    absolute: true,
    opacity: 1,
    overlay: false,
    coupon: ""
  }),
  methods: {
    toUpper() {
      this.coupon = this.coupon.toUpperCase();
    },
    registCode() {
      if (this.$store.state.registStatus) {
        this.overlay = true;
      } else {
        alert("쿠폰은 10.31 13:00 (UTC) 부터 등록할 수 있습니다.");
      }
    },
    checkCode() {
      const couponCode: string = getCookie("coupon") || "";
      if (couponCode.length === 12) {
        alert("이미 등록된 쿠폰이 있습니다.");
        return;
      }

      if (!/[A-Z0-9]{12}/.test(this.coupon)) {
        alert("코드가 유효하지 않습니다. 다시한번 확인해 주세요.");
        return;
      }

      this.authorizeCode();
    },
    authorizeCode() {
      wretch()
        .url("https://kamp-api.wecandeotest.io/registcode")
        .options({ mode: "cors" })
        .post({ code: this.coupon })
        .res(res => {
          if (res.ok) {
            setCookie("coupon", this.coupon, { expires: 1 });
            this.$store.commit(
              "setSavedCoupon",
              (getCookie("coupon") || "") == this.coupon
            );
          } else {
            console.log("auth error => ", JSON.stringify(res));
            throw new Error(`[${res.status}]${res.statusText}`);
          }
        })
        .catch(err => {
          console.log("api error => ", JSON.stringify(err));
          let errMsg = "오류가 발생했습니다.";
          if (err.text) {
            const errText = JSON.parse(err.text);
            errMsg = errText.message;
          }
          alert(errMsg);
        });
    }
  }
});
