























































































































import Vue from "vue";
import Header from "./components/layout/Header.vue";
import LivePlayer from "./components/views/LivePlayer.vue";
import LiveChat from "./components/views/LiveChatLegacy.vue";
import RedeemCode from "./components/views/RedeemCode.vue";
import { LiveState } from "./main";
import Slide1 from "@/assets/images/img_slide_01.jpg";
import Slide2 from "@/assets/images/img_slide_02.jpg";
import Slide3 from "@/assets/images/img_slide_03.jpg";
import Slide4 from "@/assets/images/img_slide_04.jpg";
import Slide5 from "@/assets/images/img_slide_05.jpg";
import Slide6 from "@/assets/images/img_slide_06.jpg";
import Slide7 from "@/assets/images/img_slide_07.jpg";
import Slide8 from "@/assets/images/img_slide_08.jpg";
import Slide9 from "@/assets/images/img_slide_09.jpg";

const App = Vue.extend({
  name: "App",
  computed: {
    liveStatus(): LiveState {
      return this.$store.state.liveStatus;
    },
    savedCode() {
      return this.$store.state.savedCoupon;
    },
    redeemStatus() {
      return this.$store.state.redeemStatus;
    },
    redeemUrl() {
      return this.$store.state.user.device.type == undefined ||
        this.$store.state.user.device.type == "console"
        ? "https://play.google.com/store/apps/details?id=com.dy.kamp"
        : this.$store.state.user.os.name == "iOS" ||
          this.$store.state.user.browser.name == "Safari"
        ? "javascript:alert('Coming soon!');"
        : "https://app.adjust.com/1pzzu9s?deeplink=kampapp%3A%2F%2Fcoopon%3Fparmfir%3Dkamp1%26parmsec%3Dspecial";
    },
    redeemTarget() {
      return this.$store.state.user.os.name == "iOS" ||
        this.$store.state.user.browser.name == "Safari"
        ? "_self"
        : "_blank";
    }
  },
  components: {
    Header,
    LivePlayer,
    LiveChat,
    RedeemCode
  },
  data() {
    return {
      items: [
        {
          src: Slide1
        },
        {
          src: Slide2
        },
        {
          src: Slide3
        },
        {
          src: Slide4
        },
        {
          src: Slide5
        },
        {
          src: Slide6
        },
        {
          src: Slide7
        },
        {
          src: Slide8
        },
        {
          src: Slide9
        }
      ]
    };
  }
});

export default App;
